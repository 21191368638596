<template>
  <v-container fluid class="pa-0 list-selector">
    <div
      class="text-h4 font-weight-bold primary--text text--lighten-1 text-center mx-4 my-0"
    >
      {{ $t("list.addToFavorites") }}
    </div>
    <v-list>
      <template v-for="(list, index) in whishlistLists">
        <v-list-item :key="list.id" @click="selectList(list.id)">
          <v-list-item-title>
            <v-badge
              color="primary"
              inline
              :content="list.size"
              :value="list.size > 0"
            >
              {{ list.name }}
            </v-badge>
          </v-list-item-title>
        </v-list-item>
        <v-divider :key="index"></v-divider>
      </template>
    </v-list>
    <v-toolbar
      bottom
      :absolute="$vuetify.breakpoint.smAndDown"
      elevation="0"
      class="mt-0 mt-md-5"
    >
      <v-row no-gutters>
        <v-col cols="6" class="px-2">
          <v-text-field
            label="Crea nuova lista"
            hide-details="auto"
            v-model="listName"
            dense
            outlined
            required
          ></v-text-field>
        </v-col>
        <v-col cols="6" class="px-2">
          <v-btn
            @click="createAndSelect"
            depressed
            block
            color="secondary"
            class="primary--text"
            >CREA</v-btn
          >
        </v-col>
      </v-row>
    </v-toolbar>
  </v-container>
</template>
<style scoped lang="scss">
.list-selector {
  .v-list {
    height: 400px;
    overflow-y: auto;
  }
  .v-toolbar {
    width: 100%;
    .v-toolbar__content {
      width: 100%;
    }
    .v-btn {
      height: 40px;
    }
  }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    height: calc(100vh - 40px);
    .v-list {
      height: calc(100vh - (80px + 56px));
    }
  }
}
</style>
<script>
import list from "~/mixins/list";

export default {
  name: "ListSelector",
  mixins: [list],
  computed: {
    listMaxHeight() {
      return 200 + "px";
    },
    whishlistLists() {
      return this.lists;
    }
  },
  methods: {
    selectList(listId) {
      this.$emit("submit", { listId: listId });
    },
    async createAndSelect() {
      let list = await this.createList();
      this.$emit("submit", { listId: list.id });
    }
  },
  created() {
    this.reload();
  }
};
</script>
