<template>
  <div class="checkout">
    <v-container fluid v-if="getProposals('header-banner').length > 0">
      <ProposalSlider :proposals="getProposals('header-banner')" />
    </v-container>
    <Breadcrumb class="hidden-sm-and-down px-0" :items="breadcrumbs" />
    <CategoryTitle :category="category" class="ma-7" />
    <v-container fluid class="pt-0">
      <v-row>
        <v-col cols="12" md="8" class="cart-info pa-0 pa-sm-3">
          <CartInfoList class="cart-info-list py-8 rounded-lg" :isCart="true" />

          <!-- visualizzazione phone -->
          <v-col
            class="summary-cards d-flex d-md-none flex-column pa-0 pa-sm-3"
            cols="12"
          >
            <GiftCode
              :giftCertificatesReceived="giftCertificatesReceived"
              @addGiftCode="addUserGiftCode"
              @sendGiftCode="sendUserGiftCode"
            />

            <CheckoutSummary
              :orderCart="cart"
              @removeGiftCode="removeUserGiftCode"
            />
          </v-col>
          <GiftListSlider
            :key="renderKey"
            :timeslotId="cart.timeslot.date + cart.timeslot.timeslotId"
            :userId="cart.user.userId"
            :warehouseId="cart.warehouse.warehouseId"
            :layout="36"
            :limit="24"
            :lg="3"
            :xl="4"
            :paginationClass="'swiper-pagination-linea-220'"
            :showArrows="!$vuetify.breakpoint.xsOnly"
            :showBullets="$vuetify.breakpoint.xsOnly"
          />

          <CartItemList :full="true" />

          <v-col cols="12" class="pt-0">
            <!-- <v-row no-gutters>
              <v-col cols="12">
                <p class="disclaimer-info">
                  {{ $t("checkout.disclaimer") }}
                </p>
              </v-col>
            </v-row> -->
            <v-alert
              v-if="error"
              type="error"
              border="left"
              transition="v-fab-transition"
            >
              {{ error }}
            </v-alert>
            <v-row class="checkout-actions">
              <v-col cols="12" sm="6" class="pa-1">
                <v-btn block large color="primary" outlined depressed to="/">
                  {{ $t("cart.button.continue") }}
                </v-btn> </v-col
              ><v-col cols="12" sm="6" class="pa-1">
                <v-btn
                  block
                  large
                  color="primary"
                  outlined
                  depressed
                  :disabled="cart.totalItems == 0"
                  @click="addAllToFavorites"
                >
                  {{ $t("cart.button.addToFavorites") }}
                </v-btn>
              </v-col>

              <v-col cols="12" sm="6" class="pa-1">
                <!-- <v-btn
                  color="primary"
                  text
                  @click="emptyCartConfirm"
                  class="empty-cart text-decoration-underline text-capitalize mr-3 font-weight-bold col-sm-6"
                  >{{ $t("cart.button.emptyCart") }}</v-btn
                > -->
                <v-btn
                  block
                  color="primary lighten-1"
                  large
                  @click="fastPayment"
                  depressed
                  :loading="loading"
                >
                  <span>{{ $t("cart.button.fastPayment") }}</span>
                </v-btn>
              </v-col>
              <v-col cols="12" sm="6" class="pa-1">
                <v-btn
                  block
                  color="secondary"
                  large
                  @click="goToPayment"
                  depressed
                  :loading="loading"
                >
                  {{ $t("cart.button.goToPayment") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-col>
        <!-- visualizzazione desktop -->
        <v-col
          class="summary-cards d-none d-md-flex flex-column"
          cols="12"
          md="4"
        >
          <GiftCode
            class="mb-0 py-9 px-7"
            :giftCertificatesReceived="giftCertificatesReceived"
            @addGiftCode="addUserGiftCode"
            @sendGiftCode="sendUserGiftCode"
          />

          <CheckoutSummary
            class="py-12 px-7"
            :orderCart="cart"
            @removeGiftCode="removeUserGiftCode"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-spacer class="spacer-scroll"></v-spacer>
    <v-container v-if="getProposals('footer-banner').length > 0">
      <ProposalSlider :proposals="getProposals('footer-banner')" />
    </v-container>
  </div>
</template>
<style scoped lang="scss">
.cart {
  position: relative;
  padding-bottom: 56px;
}
</style>
<script>
import CheckoutSummary from "@/components/cart/CheckoutSummary.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import CartItemList from "@/components/cart/CartItemList.vue";
import GiftCode from "@/components/GiftCode.vue";
import CartInfoList from "@/components/cart/CartInfoList.vue";
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import ListSelector from "@/components/lists/ListSelector.vue";
import ProposalSlider from "@/components/proposal/ProposalSlider.vue";
import GiftListSlider from "@/components/product/GiftListSlider.vue";
import RegistrationService from "~/service/userService";
import ListService from "~/service/listService";
import AnalyticsService from "~/service/analyticsService";
import FastPayment from "@/components/cart/FastPayment.vue";

import productMixins from "~/mixins/product";
import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
import categoryMixins from "~/mixins/category";

import { mapActions, mapState, mapGetters } from "vuex";
import { forEachCartItem } from "~/service/ebsn";

export default {
  name: "Checkout",
  mixins: [login, productMixins, cartInfo, categoryMixins],
  components: {
    CategoryTitle,
    CartItemList,
    CheckoutSummary,
    GiftCode,
    ProposalSlider,
    Breadcrumb,
    CartInfoList,
    GiftListSlider
  },
  data() {
    return {
      error: null,
      loading: false,
      giftCertificatesReceived: [],
      emailKey: 1,
      showAlternativesTooltip: false,
      renderKey: 1
    };
  },
  computed: {
    breadcrumbs() {
      let breadCrumbs = [];

      breadCrumbs.push({
        to: { name: "Home" },
        text: "Homepage",
        exact: true
      });
      breadCrumbs.push({
        to: {
          name: "Checkout",
          params: {
            pathMatch: this.category.slug
          }
        },
        text: this.category.name,
        exact: true
      });
      return breadCrumbs;
    },
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      getCartStatus: "cart/getCartStatus"
    })
  },

  methods: {
    ...mapActions({
      lockCart: "cart/lockCart",
      emptyCart: "cart/emptyCart",
      addGiftCode: "cart/addGiftCode",
      sendGiftCode: "cart/sendGiftCode",
      removeGiftCode: "cart/removeGiftCode"
    }),
    async fastPayment() {
      let _this = this;
      let res = await _this.$dialog.show(FastPayment, {
        waitForResult: true,
        width: 500
      });
      if (res) {
        // let items = [];
        // forEachCartItem(_this.cart, function(item) {
        //   items.push(item);
        // });
        // await ListService.addProductsToList(res.listId, items);
      }
    },
    async emptyCartConfirm() {
      if (this.cart.totalItems > 0) {
        let res = await global.vm.$dialog.confirm({
          text: global.vm.$t("message.emptyCart")
        });
        if (res) {
          this.emptyCart();
        }
      }
    },
    async addUserGiftCode(giftCodeId) {
      let cart = await this.addGiftCode(giftCodeId);
      if (cart) {
        this.getUserDetails();
      }
    },
    async sendUserGiftCode(giftCodeId) {
      let cart = await this.sendGiftCode(giftCodeId);
      if (cart) {
        this.getUserDetails();
      }
    },
    async removeUserGiftCode(giftCodeId) {
      let cart = await this.removeGiftCode(giftCodeId);
      if (cart) {
        this.getUserDetails();
      }
    },
    async getUserDetails() {
      const data = await RegistrationService.getUserDetail();
      this.giftCertificatesReceived = data ? data.giftCertificatesReceived : [];
    },
    async addAllToFavorites() {
      let _this = this;
      let res = await _this.$dialog.show(ListSelector, {
        waitForResult: true,
        fullscreen: _this.$vuetify.breakpoint.xs,
        width: 300
      });
      // let newQuantity = CartService.plus(vm.product, vm.quantity);
      if (res) {
        let items = [];
        forEachCartItem(_this.cart, function(item) {
          items.push(item);
        });
        await ListService.addProductsToList(res.listId, items);
      }
    },
    async handleEditTimeslot() {
      await this.openTimeslotSelector();
      this.renderKey++;
    },
    async goToPayment() {
      let vm = this;
      vm.loading = true;
      try {
        if (await this.needAddress()) {
          if (await this.needTimeslot()) {
            await vm.lockCart();
            vm.$router.push({
              name: "Payment"
            });
          }
        }
      } catch (err) {
        await this.needTimeslot();
        vm.error = err.message;
      } finally {
        vm.loading = false;
      }
    }
  },
  created() {
    global.EventBus.$on("timeslotchanged", () => {
      this.renderKey++;
    });
  },
  mounted() {
    AnalyticsService.beginCheckout(this.cart);
    this.getUserDetails();
  }
};
</script>
