<template>
  <v-card flat class="gift-code">
    <v-row class="d-flex flex-column">
      <v-col cols="12">
        <h3>{{ $t("cart.giftCode.title") }}</h3>

        <v-row
          no-gutters
          class="gift-code-row gift-certificate d-flex justify-space-between align-center mb-2"
          v-for="gift in giftCertificatesReceived"
          :key="gift.userGiftCertificateId"
        >
          <v-col cols="8">
            <span class="summary-text white--text d-flex justify-space-between"
              >{{ gift.name }}
              <span v-if="gift.amount && gift.amount > 0" class="pr-2">{{
                $n(gift.amount, "currency")
              }}</span></span
            >
          </v-col>
          <v-col cols="4">
            <v-btn
              color="primary"
              class="add-gift-btn bg-white font-weight-bold"
              depressed
              x-small
              outlined
              v-on:click="addGiftCode(gift.userGiftCertificateId)"
            >
              USA
            </v-btn>
          </v-col>
        </v-row>

        <v-row
          no-gutters
          class="gift-code-row d-flex align-center justify-space-between"
        >
          <v-col cols="8">
            <v-text-field
              :placeholder="$t('cart.giftCode.inputPlaceholder')"
              hide-details="auto"
              v-model="giftCode"
              class="mr-2"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-btn
              elevation="0"
              x-small
              outlined
              color="primary"
              depressed
              class="bg-white font-weight-bold"
              v-on:click="sendGiftCode(giftCode)"
              :disabled="createDisabled"
              >{{ $t("cart.giftCode.button.apply") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<style lang="scss">
.gift-code {
  background-color: $white;
  padding: 10px;
  border-radius: 5px 5px 0 0 !important;
  .summary-text {
    font-size: 13px;
    font-weight: 700;
  }

  .gift-code-row {
    margin-top: 10px;
    min-height: 40px;
    .v-input {
      background: $white;
      margin: 0;
      padding: 0;
      height: 40px;
      border-radius: 5px;
      fieldset {
        border: none;
      }
      input {
        color: $primary;
      }
      ::placeholder {
        color: #c4c4c4;
      }

      :-ms-input-placeholder {
        color: #c4c4c4;
      }

      ::-ms-input-placeholder {
        color: #c4c4c4;
      }
    }

    .v-btn {
      height: 40px;
      border: none;
      border-radius: 5px;
      box-shadow: none !important;
    }
  }
}
</style>

<script>
export default {
  name: "GiftCode",
  props: { giftCertificatesReceived: { type: Array, required: true } },
  data() {
    return {
      giftCode: "",
      createDisabled: false
    };
  },
  methods: {
    addGiftCode(giftCodeId) {
      this.$emit("addGiftCode", giftCodeId);
    },
    sendGiftCode(giftCode) {
      this.$emit("sendGiftCode", giftCode);
    }
  }
};
</script>
